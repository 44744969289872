export const chartItemValuesMap: Record<string, string> = {
  value: 'Средняя оценка',
  by_lead: 'Руководитель',
  by_coworkers: 'Коллеги',
  by_subordinates: 'Подчинённые',
};

export const monthMappings: Record<string, string> = {
  January: 'Январь',
  Febuary: 'Февраль',
  March: 'Март',
  April: 'Апрель',
  May: 'Май',
  June: 'Июнь',
  July: 'Июль',
  August: 'Август',
  September: 'Сентябрь',
  October: 'Октябрь',
  November: 'Ноябрь',
  December: 'Декабрь',
};

export const monthToSeasonMappings: Record<string, string> = {
  January: 'Зима',
  Febuary: 'Зима',
  March: 'Весна',
  April: 'Весна',
  May: 'Весна',
  June: 'Лето',
  July: 'Лето',
  August: 'Лето',
  September: 'Осень',
  October: 'Осень',
  November: 'Осень',
  December: 'Зима',
};

const allColors = [
  '#dd1c9b',
  '#5438DC',
  '#3BADEF',
  '#0cc095',
  'orange',
  'rgba(255,255,255,0.4)',
];
let colorIndex = 0;
const colorsMap: Record<string, string> = {};

export const getDateColor = (color: string) => {
  if (colorsMap[color]) {
    return colorsMap[color];
  }

  colorsMap[color] = allColors[colorIndex] || 'black';
  colorIndex += 1;

  return colorsMap[color];
};
